import { Link } from "@remix-run/react";
import { useCart } from "../../context/CartContext";

export default function MobileNavigation() {
  const { cartLoaded, cartIsEmpty, productCount } = useCart();
  return (
    <div className="fixed block lg:hidden bottom-0 left-0 right-0 z-10 shadow-xl shadow-gray-950 border-t border-[#f0f0f0] bg-white">
      <div className="flex justify-around items-baseline text-sm">
        <div>
          <Link
            to="/"
            reloadDocument
            className="text-black flex flex-col items-center justify-center h-[60px] px-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>

            <span>Home</span>
          </Link>
        </div>
        <div>
          <Link
            to="/orders"
            reloadDocument
            className="text-black flex flex-col items-center justify-center h-[60px] px-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
              />
            </svg>

            <span>Orders</span>
          </Link>
        </div>
        <div>
          <Link
            to="/cart"
            reloadDocument
            className="text-black relative flex flex-col items-center justify-center h-[60px] px-3"
          >
            <svg
              fill="none"
              viewBox="0 0 19 17"
              width="22"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L2.87153 1.62385C3.56699 1.85566 4.07989 2.44939 4.2082 3.17114L5.30668 9.3501C5.47638 10.3046 6.30628 11 7.2758 11H14.896C15.7868 11 16.5702 10.4109 16.8174 9.5551L18.2619 4.55508C18.6313 3.27618 17.6716 2 16.3404 2H7M8 14.5C8 15.3284 7.32843 16 6.5 16C5.67157 16 5 15.3284 5 14.5C5 13.6716 5.67157 13 6.5 13C7.32843 13 8 13.6716 8 14.5ZM16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5Z"
                style={{
                  strokeLinecap: "round",
                  strokeWidth: "1.25",
                  stroke: "currentcolor",
                }}
              ></path>
            </svg>
            {cartLoaded && !cartIsEmpty ? (
              <span className="bg-black text-primary w-[20px] h-[20px] text-xs absolute leading-[12px] right-0 top-0 rounded-[50%] flex items-center justify-center">
                {productCount}
              </span>
            ) : null}
            <span>Cart</span>
          </Link>
        </div>
        <div>
          <Link
            to="/account"
            reloadDocument
            className="text-black flex flex-col items-center justify-center h-[60px] px-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>

            <span>Account</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
