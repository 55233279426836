import { Link } from "@remix-run/react";

export default function ErrorCatchAll({ error }) {
  return (
    <div className="h-screen flex justify-center items-center text-center px-4">
      <div>
        <h1 className="font-bold mb-4 text-xl">Something went wrong</h1>
        <p className="mb-2">
          {error.statusText || "An unexpected error occurred"}
        </p>
        <Link to="/" reloadDocument className="underline">
          Go back to the homepage
        </Link>
      </div>
    </div>
  );
}
