import { Link } from "@remix-run/react";

export default function Error() {
  return (
    <div className="h-screen flex justify-center items-center text-center px-4">
      <div>
        <h1 className="font-bold mb-4 text-xl">404 - Page Not Found</h1>
        <p className="mb-2">
          Sorry, the page you're looking for doesn't exist.
        </p>
        <Link to="/" reloadDocument className="underline">
          Go back to the homepage
        </Link>
      </div>
    </div>
  );
}
