import { Dialog } from "@headlessui/react";
import BusinessSelection from "./BusinessSelection";

export default function BusinessSelectionDialog({
  brand,
  terminology,
  isOpen,
  setIsOpen,
  selectedBusiness,
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-auto mt-[72px] lg:m-4"
      aria-labelledby="BusinessSelectionDialog-dialog-title"
      aria-describedby="BusinessSelectionDialog-dialog-description"
    >
      <div className="fixed inset-0 bg-black opacity-70" />

      <div className="relative border-t-8 lg:border-8 border-primary bg-black rounded-tl-lg rounded-tr-lg lg:rounded-bl-lg lg:rounded-br-lg shadow-lg max-w-[1024px] lg:max-w-[500px] w-full h-full overflow-scroll lg:h-auto z-20">
        {selectedBusiness ? (
          <div
            id="dialog-title"
            className="absolute top-0 right-0 left-0 px-3 h-14 text-lg leading-6 text-white font-bold flex items-center justify-between"
          >
            <div></div>

            <button onClick={() => setIsOpen(false)} className="text-white">
              <svg width="28" height="28" viewBox="0 0 20 20">
                <path
                  fill="none"
                  style={{ strokeWidth: "1.06px", stroke: "currentcolor" }}
                  d="M16,16 L4,4"
                ></path>
                <path
                  fill="none"
                  style={{ strokeWidth: "1.06px", stroke: "currentcolor" }}
                  d="M16,4 L4,16"
                ></path>
              </svg>
            </button>
          </div>
        ) : null}
        <div
          id="BusinessSelectionDialog-dialog-description"
          className="mt-[60px] lg:mt-0 py-4 px-4 text-sm text-gray-600"
        >
          <BusinessSelection
            brand={brand}
            terminology={terminology}
            selectedBusiness={selectedBusiness}
          />
        </div>
      </div>
    </Dialog>
  );
}
