import { Form, Link, useActionData, useNavigation } from "@remix-run/react";
import { useEffect, useState } from "react";
import { LoadingIndicator } from "./LoadingIndicator";
import { DELIVERY_TRESHOLD } from "../../config/constants";
import dayjs from "dayjs";

export default function BusinessSelection({
  brand,
  selectedBusiness,
  terminology,
}) {
  const actionData = useActionData();
  const navigation = useNavigation();
  const [zipcode, setZipcode] = useState("");
  const [business, setBusiness] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isSubmitting = navigation.state === "submitting";

  let zipcodeResponse;
  if (actionData && actionData.zipcodeResponse) {
    zipcodeResponse = actionData.zipcodeResponse;
  }

  let businessResponse;
  if (actionData && actionData.businessResponse) {
    businessResponse = actionData.businessResponse;
  }

  useEffect(() => {
    setBusiness(selectedBusiness);
  }, [selectedBusiness]);

  const handleBusinessChange = (newBusiness, e) => {
    e.preventDefault(); // Prevent default form submission
    setIsLoading(true);
    setBusiness(newBusiness);
    //localStorage.removeItem("cart");

    // Submit the form after confirmation
    e.target.form.submit();
    // Show confirmation dialog
    // const isConfirmed = window.confirm(
    //   "Changing your delivery option will empty your cart. Are you sure you want to proceed?"
    // );

    // if (isConfirmed) {
    //   // Set the new business type
    //   setBusiness(newBusiness);
    //   localStorage.removeItem("cart");

    //   // Submit the form after confirmation
    //   e.target.form.submit();
    // } else {
    //   setIsLoading(false);
    // }
  };

  return (
    <div className="flex items-center justify-center bg-black text-white">
      <div className="text-center p-4 rounded-lg shadow-lg max-w-md flex flex-col gap-3 items-center">
        <div className="mb-4">
          <img
            src={brand.data.logo}
            alt="store-logo"
            className="max-h-[80px]"
          />
        </div>
        <p className="mb-4 text-base lg:text-lg">
          {terminology.business.selection}
        </p>
        <p className="text-red-600">
          {terminology.business.store_switch_notice}
        </p>
        <Form method="post" className="w-full" reloadDocument={false}>
          <input type="hidden" name="formId" value="businessTypeForm" />
          <div className="flex flex-col lg:flex-row gap-3 justify-center">
            <label
              className={`${
                business === "local"
                  ? "bg-green border-green hover:bg-green text-white hover:opacity-90 hover:text-white"
                  : "text-primary border-primary hover:bg-primary "
              } text-base flex gap-2 items-center flex-grow justify-center  py-2 px-3 border-2 hover:text-black transition-all duration-300 border-opacity-50 rounded focus:outline-none focus:ring-2 focus:ring-primary cursor-pointer`}
            >
              <input
                type="radio"
                name="business"
                value="local"
                checked={business === "local"}
                onChange={(e) => handleBusinessChange("local", e)}
                className="hidden"
              />
              {business === "local" && (
                <svg width="20" height="20" viewBox="0 0 20 20">
                  <polyline
                    fill="none"
                    stroke="currentcolor"
                    strokeWidth="1.1"
                    points="4,10 8,15 17,4"
                  ></polyline>
                </svg>
              )}
              Local{" "}
              {isLoading && business === "local" ? <LoadingIndicator /> : null}
            </label>

            {/* Nationwide Shipping Option */}
            <label
              className={`${
                business === "nationwide"
                  ? "bg-green border-green hover:bg-green text-white hover:opacity-90 hover:text-white"
                  : "text-primary border-primary hover:bg-primary "
              } text-base flex gap-2 items-center justify-center flex-grow py-2 px-3 border-2 hover:text-black transition-all duration-300 border-opacity-50 rounded focus:outline-none focus:ring-2 focus:ring-primary cursor-pointer`}
            >
              <input
                type="radio"
                name="business"
                value="nationwide"
                checked={business === "nationwide"}
                onChange={(e) => handleBusinessChange("nationwide", e)}
                className="hidden"
              />
              {business === "nationwide" && (
                <svg width="20" height="20" viewBox="0 0 20 20">
                  <polyline
                    fill="none"
                    stroke="currentcolor"
                    strokeWidth="1.1"
                    points="4,10 8,15 17,4"
                  ></polyline>
                </svg>
              )}
              Nationwide
              {isLoading && business === "nationwide" ? (
                <LoadingIndicator />
              ) : null}
            </label>
          </div>
        </Form>
        <div className="text-sm italic mt-3">
          {terminology.business.local_delivery_zone_notice}
        </div>

        <Form
          method="post"
          className="w-full mt-3 flex flex-col lg:flex-row gap-3 items-center"
        >
          <input type="hidden" name="formId" value="zipcodeForm" />
          <input
            type="text"
            name="zipcode" // Name must match what the action function expects
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
            placeholder="Enter Zip Code"
            className="p-2 bg-[#333333] w-full h-[44px] text-base border-2 border-[#333] text-white rounded focus:outline-none focus:ring-2 focus:ring-primary"
          />
          <button
            type="submit"
            className="bg-black flex justify-center items-center text-base w-full text-primary border-2 border-primary px-4 h-[44px] rounded hover:bg-primary-dark transition-all"
          >
            {isSubmitting ? <LoadingIndicator /> : "Check Zip Code"}
          </button>
        </Form>

        {/* Show success or error messages */}
        {zipcodeResponse?.error && (
          <div className="text-red-500 mt-2">{zipcodeResponse.message}</div>
        )}

        {zipcodeResponse?.success &&
          (zipcodeResponse.data && zipcodeResponse.data.business === "local" ? (
            <div className="text-white mt-2">
              Your zip code qualifies for our{" "}
              <strong className="text-green">Local Delivery</strong> service.
              Enjoy fast, local delivery to your doorstep.
            </div>
          ) : (
            <div>
              <div className="text-white mt-2">
                We offer{" "}
                <strong className="text-green">Nationwide Shipping</strong> to
                your area. You can proceed with nationwide option for your
                order.
              </div>
              {/* {zipcodeResponse.data?.business_transit_days ? (
                <div className="mt-2">
                  Shipping to your area takes{" "}
                  {zipcodeResponse.data.business_transit_days} business days.
                  Place your order now to receive it by{" "}
                  {dayjs(zipcodeResponse.data.delivery_date).format(
                    "MMMM D, YYYY"
                  )}
                  .
                </div>
              ) : null} */}
            </div>
          ))}
      </div>
    </div>
  );
}

function handleBusinessSelection(selection) {
  // Post the selection to the server to save it in the session
  fetch("/set-business", {
    method: "POST",
    body: new URLSearchParams({ business: selection }),
  }).then(() => {
    window.location.reload(); // Reload to reflect the business selection in the UI
  });
}
